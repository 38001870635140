
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BooksValuesStore from "@/modules/books-values/books-values-store";
import BooksValuesPaginate from "@/components/paginate.vue";
import BooksStore from "@/modules/books/books-store";
import SchoolsTypesStore from "@/modules/schools-types/schools-types-store";
import Swal from "sweetalert2";
import BooksValuesDelete from "@/modules/books-values/components/books-values-delete.vue";
// import BooksValuesRemove from "@/modules/books-values/components/books-values-remove.vue";
import BooksValuesShow from "@/modules/books-values/components/books-values-show.vue";

export default {
  name: "Preços dos Livros",
  data() {
    return {
      title: "Preços dos Livros",
      single: "Preço do Livro",
      items: [
        {text: "Home", to: {name: "Home"}},
        {text: "Preços dos Livros", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Livro", key: "books.label"},
        {label: "Tipo de escola", key: "schools_types.label"},
        {label: "Valor", key: "value"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
        filtro_id_books: null,
      form: {
        id_books:null,
        id_schools_types:null,
        value:null,
      },
      optionsBooks: [],
      optionsSchoolsTypes: [],
      editando: false,
    }
  },
  components: {BooksValuesShow, /*BooksValuesRemove,*/ BooksValuesDelete, BooksValuesPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
    this.getBooks();
    this.getSchoolsTypes();
  },
  methods: {
    getBooks() {
      BooksStore.all().then((response) => {
        this.optionsBooks = [{value: null, text: "Selecione um livro"}];
        response.data.data.forEach((item) => {
          this.optionsBooks.push({value: item.id, text: item.label});
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    getSchoolsTypes() {
      SchoolsTypesStore.all().then((response) => {
        this.optionsSchoolsTypes = [];
        response.data.forEach((item) => {
          this.optionsSchoolsTypes.push({value: item.id, text: item.label});
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      BooksValuesStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
          id_books: this.filtro_id_books,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.editando = false;
      this.form = {
        id_books:null,
        id_schools_types:null,
        value:null,
      }
    },
    edit(item) {
      this.form = {...item};
      this.editando = true;
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.editando) {
        BooksValuesStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Preço do livro atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o preço do livro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BooksValuesStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Preço do livro criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o preço do livro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                      <div class="col-md-3">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                      <div class="col-6 col-md-3">
                          <b-form-select id="input-0" @input="getPaginate(1)" v-model="filtro_id_books" :options="optionsBooks"></b-form-select>
                      </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                        <button @click="buscar=null; filtro_id_books=null, getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="editando">Editando</span>
                      <span v-if="!editando">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!editando">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="editando">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">



                              <b-form-group label="Livro" label-for="input-0" description="Livro">
                                <b-form-select id="input-0" v-model="form.id_books" :options="optionsBooks" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Tipo de escola" label-for="input-0" description="Tipo de escola">
                                <b-form-select id="input-0" v-model="form.id_schools_types" :options="optionsSchoolsTypes" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Preço do livro" label-for="input-2" description="informe o preço do livro para esse tipo de escola">
                                <b-form-input id="input-2" v-model="form.value" required></b-form-input>
                              </b-form-group>
                              <b-button type="submit" variant="primary">
                                <span v-if="editando">Salvar alterações</span>
                                <span v-if="!editando">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <books-values-paginate :paginate="paginate" @change-page="getPaginate"></books-values-paginate>
              <b-table striped hover :items="list" :fields="table">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)"  class="btn pl-4 pr-4 btn-sm btn-info" ><i class="fa fa-edit"></i> Editar </button>
                  <books-values-delete :value="data.item" @change="getPaginate(1)"></books-values-delete>
<!--                  <books-values-remove :value="data.item" @change="getPaginate(1)"></books-values-remove>-->
                  <books-values-show :value="data.item"></books-values-show>
                </template>
              </b-table>
              <books-values-paginate :paginate="paginate" @change-page="getPaginate"></books-values-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

