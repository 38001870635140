
import BooksValuesService from "@/modules/books-values/books-values-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BooksValuesService.create(payload).then(response => {
                resolve({
                    message: "BooksValues criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BooksValues",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BooksValuesService.update(payload).then(response => {
                resolve({
                    message: "BooksValues atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BooksValues",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BooksValuesService.remove(id).then(response => {
                resolve({
                    message: "BooksValues removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BooksValues",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id_books,id_schools_types) {
        return new Promise((resolve, reject) => {
            BooksValuesService.delete(id_books,id_schools_types).then(response => {
                resolve({
                    message: "BooksValues deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BooksValues",
                    success: false,
                    error
                });
            })
        })
    },
    get(id_books,id_schools_types) {
        return new Promise((resolve, reject) => {
            BooksValuesService.get(id_books,id_schools_types).then(response => {
                resolve({
                    message: "BooksValues encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksValues",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BooksValuesService.paginate(query).then(response => {
                resolve({
                    message: "BooksValues encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksValues",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BooksValuesService.all().then(response => {
                resolve({
                    message: "BooksValues encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksValues",
                    success: false,
                    error
                });
            })
        })
    }
}
