
import SchoolsTypesService from "@/modules/schools-types/schools-types-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            SchoolsTypesService.create(payload).then(response => {
                resolve({
                    message: "SchoolsTypes criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar SchoolsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            SchoolsTypesService.update(payload.id,payload).then(response => {
                resolve({
                    message: "SchoolsTypes atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar SchoolsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            SchoolsTypesService.remove(id).then(response => {
                resolve({
                    message: "SchoolsTypes removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover SchoolsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            SchoolsTypesService.delete(id).then(response => {
                resolve({
                    message: "SchoolsTypes deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar SchoolsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            SchoolsTypesService.get(id).then(response => {
                resolve({
                    message: "SchoolsTypes encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            SchoolsTypesService.paginate(query).then(response => {
                resolve({
                    message: "SchoolsTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsTypes",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            SchoolsTypesService.all().then(response => {
                resolve({
                    message: "SchoolsTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsTypes",
                    success: false,
                    error
                });
            })
        })
    }
}
