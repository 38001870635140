
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        return axios.post("v1_genesis/v1/books_values/create", payload);
    },
    update( payload) {
        return axios.put(`v1_genesis/v1/books_values/update/${payload.id_books}/${payload.id_schools_types}`, payload);
    },
    remove(id_books,id_schools_types) {
        return axios.delete(`v1_genesis/v1/books_values/remove/${id_books}/${id_schools_types}`);
    },
    delete(id_books,id_schools_types) {
        return axios.delete(`v1_genesis/v1/books_values/delete/${id_books}/${id_schools_types}`);
    },
    get(id_books,id_schools_types) {
        return axios.get(`v1_genesis/v1/books_values/${id_books}/${id_schools_types}`);
    },
    paginate(query) {
        return axios.get(`v1_genesis/v1/books_values/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_genesis/v1/books_values/`);
    }
}
